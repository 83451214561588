import React, { useRef, useState, useEffect } from "react"

import { Form, Row } from "react-bootstrap"
import { postFormData } from "./api/Api"
import $ from 'jquery'
import InputField from './elements/input'
import FileField from './elements/file'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { phoneNumberValidation, Mail_Function } from "../common/utils"
import { CustomLinks } from "../common/menuUtils"

//import { Vacancies } from "../../queries/common_use_query"

function CareerForm(props) {

  //const {loading:loading, error:error, data:data} = Vacancies();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectError, setSelectError] = useState(false);

    const [file, setFile] = useState(""); // storing the uploaded file
// storing the recived file from backend
    const [] = useState({ name: "", path: "" });
    const [] = useState(0); // progess bar
  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  // var job_position = [];

  // if(data && data.vacancies &&  data.vacancies.length > 0){
  //   data.vacancies.map((item, index) =>{
  //     job_position.push(item.Title);
  //   })
  // }
  // job_position.push("Sending my CV for future consideration");

  const fields = ([
      {
        element: "config",
        formname: "Job Application",
        form_type: "careers",
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
        email_temp_user: "careers_user",
        email_temp_admin: "careers_admin",
        email_server_func: "careers",
        event_tracking: "careers",
        page_url: "/about/careers",
        email_subject_user: "Job Application",
        email_subject_admin: "Job Application",
      },
      
      {
        grpmd: "6",
        label: "Name",
        placeholder: "",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        fieldClass: "",
        labelClass: "content_b-18",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "6",
        label: "Telephone Number",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        fieldClass: "",
        labelClass: "content_b-18",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890."
      },
      {
        grpmd: "6",
        label: "Email Address:",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        fieldClass: "",
        labelClass: "content_b-18",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM."
      },
      
      // {
      //   grpmd: "12",
      //   label: "What position are you interested in?",
      //   placeholder: "Select Position",
      //   name: "positions",
      //   element: "select",
      //   required: true,
      //   values: job_position,
      //   labelClass: "annual-txt",
      //   class: "full_wdth_box post_cls",
      //   errorMsg: "Select a Position."
      // },
      {
        grpmd: "6",
        label: "MESSAGE",
        placeholder: "",
        name: "message",
        element: "textarea",
        class: "",
        required: true,
        rows:"4",
        labelClass: "annual-txt",
        errorMsg: "Enter the enquiry message."
      },
      {
        grpmd: "6",
        label: "Your CV",
        placeholder: "Click here to attach your CV",
        name: "file",
        type: "file",
        element: "file",
        required: true,
        accept:".pdf, .doc",
        accept_file_formats:".pdf or .doc",
        fieldClass: "form-control-browsefile custom custom-file",
        labelClass: "content_b-18",
        id:"custom-file",
        show_formats:true,
        errorMsg: "Attach your CV."
      },
      {
        type: "hidden",
        element: "input",
        name:"cvname"
      },
      
      {
        grpmd: "12",
        label: 'I agree to the <a href="/'+CustomLinks.terms_conditions+'/" className="content-link" target="_blank">Terms & Conditions</a> and <a href="/'+CustomLinks.privacy_policy+'/" className="content-link" target="_blank">Privacy & Data Protection Policy</a>',
        placeholder: "I agree to the Terms & Conditions and Privacy & Data Protection Policy",
        name: "agree_status",
        type: "checkbox",
        element: "checkbox",
        required: true,
        value:"yes",
        labelClass: "",
        class: "checkbox_cnt"
      },
      {
        grpmd: "12",
        label: "I agree to receive newsletters and company updates as per the Privacy Policy.",
        placeholder: "I agree to receive newsletters and company updates as per the Privacy Policy.",
        name: "signup_newsletter",
        type: "checkbox",
        element: "checkbox",
        required: true,
        value:"yes",
        labelClass: "",
        class: "checkbox_cnt"
      },      
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "SUBMIT",
        class: "btn btn-green",
        labelClass: "",
        customClass:"custom-button-wrapper"
      },
      // {
      //   text: '<span class="terms">By clicking Submit Enquiry, you agree to our <a href="'+CustomLinks.terms_conditions+'" target="_blank">Terms & Conditions</a> and <a href="'+CustomLinks.privacy_policy+'" target="_blank">Privacy Policy</a>.</span>',
      //   element: "html",
      //   class: "mb-md-0 mb-3 content_r-m"
      // },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    if(event.type === "select"){
      //event.value = event.value.trimStart()
      setSelectedOptions(event);
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  const handleAttachment = event => {
      const fileItem = event.target.files[0]; // accesing file
      var d = document.getElementById('custom-file');
      //alert(d.value);
     $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));
     setFile(fileItem); // storing file

  };

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

        formvalues['g-recaptcha-response'] = token;

        let formData = new FormData();
        if (formvalues?.files) {
          formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
        }

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {

          if (apiRes && apiRes.id) {
            // If image from externall url, use that
            if (apiRes.attachment && apiRes.attachment[0].url.match(/(http|https):\/\//g)) {
              formvalues['file'] = `${apiRes.attachment[0].url}`;
              formvalues['cvname'] = `${apiRes.attachment[0].url}`;
            } else { // or we consider it is loading from api url
              formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
              formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
            }

            //console.log("formvalues", formvalues);

            window.grecaptcha.reset();
            await Mail_Function(formvalues);
            
            // lets send mail
            // const axiosOptions_email = {
            //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
            //   method: "post",
            //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
            //   data: qs.stringify(formvalues),
            // }

            // axios(axiosOptions_email)
            //   .then(() => {
            //     console.log('mail sent!')
            // }).catch(err =>
            //     console.log(err)
            // );

          }
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-'+fields[0].event_tracking,
          'formId': 'form-'+fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          setThankyou(false)
        }, 3000);
        
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    //setToken(token);
  };

  const handleSubmit = event => {
    if((file == '') || (file == null)) {
      $('.form-control-browsefile').addClass('fileerror');
    } else {
      $('.form-control-browsefile').removeClass('fileerror');
    }
    const form = event.currentTarget;
    var form_obj_arr = Object.entries(form);


    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))
      
      //json['name'] = json.first_name+' '+json.last_name;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;

      json['g-recaptcha-response'] = token;


      json['files'] = file;
      json['extra'] = {"agree_status": json.agree_status, "signup_newsletter": json.signup_newsletter, "job_title": json.job_title};
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      
      setFile(file);
      setFormvalues(json);
      setToken("test");

      recaptchaRef.current.execute();

      setValidated(false);

      setSelectError(false);
      setSelectedOptions([]);

      // reset form
      const form = event.target
      form.reset();
      $('.form-control-browsefile .custom-file-label').html('Click here to attach your CV');

    }
  };


  return (

    <div className="form">
        <div ref={myRef}>
            {showerror && <div className="alert alert-danger">
              {fields[0].error_text}
            </div>}
            
            {showthankyou && <div className="alert alert-success">
              {fields[0].success_text}
            </div>}

        </div>

          <Form className={props.classNames} enctype='multipart/form-data' name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
            <input type="hidden" name="form_name" value={fields[0].formname} />
            <input type="hidden" name="form_type" value={fields[0].form_type} />
            <input type="hidden" name="job_title" value={props.job_title} />
            <input type="hidden" name="to_email_id" value={process.env.GATSBY_MAIL_TO_CAREER} />
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="admin_email" value={"Yes"} />

            <Row className="custom-text-field-wrapper no_direction">
            {fields.map((field, index) => {
                if ( "input" === field.element ) {
                  return (
                    <InputField
                      name={field.name}
                      label={field.label}
                      grpmd={field.grpmd}
                      ref={field.ref}
                      type={field.type}
                      fieldClass={field.fieldClass}
                      labelClass={field.labelClass}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={`${field.element}~${index}`}
                      pattern={field.patternchk}
                      handlechange={handlechange}
                      errorMsg={field.errorMsg}
                    />
                  );
                }

                if ( "file" === field.element ) {
                  return (
                    <FileField
                      name={field.name}
                      label={field.label}
                      grpmd={field.grpmd}
                      ref={field.ref}
                      type={field.type}
                      fieldClass={field.fieldClass}
                      labelClass={field.labelClass}
                      placeholder={field.placeholder}
                      key={`${field.element}~${index}`}
                      accept={field.accept}
                      accept_file_formats={field.accept_file_formats}
                      handlechange={handleAttachment}
                      required={field.required}
                      field_id={field.id}
                      show_formats={field.show_formats}
                      errorMsg={field.errorMsg}
                    />
                  );
                }
                if ("select" === field.element) {
                  return (
                    <SelectField
                      name={field.name}
                      label={field.label}
                      grpmd={field.grpmd}
                      ref={field.ref}
                      required={field.required}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      values={field.values}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                      componentprops={props}
                      bottom_text={field.bottom_text}
                      //selectedValue={props.jobtitle ? props.jobtitle : ""}
                      selectedValue={selectedOptions}
                      errorMsg={field.errorMsg}
                      isError={selectError ? true : '' }
                    />
                  );
                }
                if ("textarea" === field.element) {
                  return (
                    <TextAreaField
                      name={field.name}
                      label={field.label}
                      grpmd={field.grpmd}
                      ref={field.ref}
                      rows={field.rows}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                    />
                  );
                }
                if ("checkbox" === field.element) {
                  return (
                    <CheckboxField
                      name={field.name}
                      label={field.label}
                      ref={field.ref}
                      value={field.value}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={`${field.name}~${index}`}
                      handlechange={handlechange}
                    />
                  );
                }
                if ("html" === field.element) {
                  return (
                    <HtmlBox
                      text={field.text}
                      fieldClass={field.class}
                      key={`${field.element}~${index}`}
                    />
                  );
                }
              if ("captcha" === field.element) {
                  return (
                    <ReCaptchaBox
                      fieldClass={field.class}
                      captRef={field.captchaRef}
                      key={`${field.element}~${index}`}
                      handleonVerify={handleonVerify}
                    />
                  );
                }

                if ("button" === field.element) {
                  return (
                    <ButtonField
                      name={field.name}
                      fieldClass={field.class}
                      type={field.type}
                      value={field.value}
                      key={`${field.element}~${index}`}
                      grpmd={field.grpmd}
                      customClass={field.customClass}
                    />
                  );
                }
              })
            }
            </Row>
          
          </Form>
    </div>

  );
}


const CareerFormPage = (props) => (
  <CareerForm {...props}/>
)

export default CareerFormPage