import React, { useState, useEffect } from "react"

import { Form, Row } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function } from "../common/utils"
import { CustomLinks } from "../common/menuUtils"

import $ from "jquery"

function ContactForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "Contact",
        form_type: "contact",
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
        email_temp_user: "contact_user",
        email_temp_admin: "contact_admin",
        email_server_func: "contact",
        event_tracking: "contact",
        page_url: "/contact",
        email_subject_user: "General Enquiry",
        email_subject_admin: "General Enquiry"
      },      
      {
        grpmd: "6",
        label: "YOUR NAME",
        placeholder: "",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "6",
        label: "TELEPHONE NUMBER",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890."
      },
      {
        grpmd: "6",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM."
      },
      {
        grpmd: "6",
        label: "MESSAGE",
        placeholder: "",
        name: "message",
        element: "textarea",
        class: "",
        required: true,
        rows:"4",
        labelClass: "annual-txt",
        errorMsg: "Enter the enquiry message."
      },
      {
        grpmd: "6",
        label: "TYPE OF ENQUIRY",
        placeholder: "",
        name: "enquirytype",
        element: "select",
        required: true,
        values: ['I am an agent looking for more details', 'I am an Investor looking for more details', 'I am a Partner looking for more details', 'I am a Developer looking for more details', 'I am a Tenant looking for more details', 'Other Enquiry'],
        labelClass: "annual-txt",
        class: "full_wdth_box"
      },
      {
        grpmd: "6",
        label: "Found Us",
        placeholder: "",
        name: "found_us",
        element: "select",
        required: true,
        values: ['Search Engine', 'Friend or Relative', 'Others'],
        labelClass: "annual-txt",
        class: "full_wdth_box"
      },
      {
        grpmd: "12",
        label: 'I agree to the <a href="/'+CustomLinks.terms_conditions+'/" className="content-link" target="_blank">Terms & Conditions</a> and <a href="/'+CustomLinks.privacy_policy+'/" className="content-link" target="_blank">Privacy & Data Protection Policy</a>',
        placeholder: "I agree to the Terms & Conditions and Privacy & Data Protection Policy",
        name: "agree_status",
        type: "checkbox",
        element: "checkbox",
        required: true,
        value:"yes",
        labelClass: "",
        class: "checkbox_cnt"
      },
      {
        grpmd: "12",
        label: "I agree to receive newsletters and company updates as per the Privacy Policy.",
        placeholder: "I agree to receive newsletters and company updates as per the Privacy Policy.",
        name: "signup_newsletter",
        type: "checkbox",
        element: "checkbox",
        required: true,
        value:"yes",
        labelClass: "",
        class: "checkbox_cnt"
      },
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit",
        class: "btn btn-green",
        labelClass: "",
        customClass:"custom-button-wrapper"
      },
      // {
      //   text: 'By clicking Submit Enquiry, you agree to our <a href="/'+CustomLinks.terms_conditions+'/" className="content-link" target="_blank">Terms</a> and <a href="/'+CustomLinks.privacy_policy+'/" target="_blank">Privacy Policy</a>.',
      //   element: "html",
      //   class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m"
      // },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    //console.log("check_event",event);
    if(event.type === "select"){
      //event.value = event.value.trimStart()
      setSelectedOptions(event);
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token);
    
    if (token !== '') {

    const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(async apiRes => {

          //console.log("formvalues", formvalues);

          window.grecaptcha.reset();
          await Mail_Function(formvalues);

      });

      formvalues['g-recaptcha-response'] = token;


      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      setTimeout(() => {
        setThankyou(false)
      }, 3000);

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }

      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    //setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;
    

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);

      if(selectedOptions?.value){
        setSelectError(false);
      } else{
        setSelectError(true);
      }
      

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });      
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = formsdata[key].type === "checkbox" ? (formsdata[key].checked === true ? 'yes' : '') : formsdata[key].value
      ))

      console.log("form_json", json)

      //json['name'] = json.first_name+' '+json.last_name;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['extra'] = {"found_us": json.found_us,"agree_status": json.agree_status, "signup_newsletter": json.signup_newsletter};


      //console.log("formsdata_json", json);

      setFormvalues(json);
      setToken("test");

      recaptchaRef.current.execute();

      setValidated(false);
      setSelectError(false);
      setSelectedOptions({});
      
      // reset form
      const form = event.target
      form.reset();
      
    }
  };

  //console.log("selectedOptions", selectedOptions.length);

  return (
    <div className="form">
    
    {showerror && <div className="alert alert-danger">
      {fields[0].error_text}
    </div>}
    <div ref={myRef}></div>
    
    {showthankyou && <div className="alert alert-success">
      {fields[0].success_text}
    </div>}

    
      <Form name={fields[0].formname} className={props.classNames} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
        
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={props.formLabel} />
        <input type="hidden" name="to_email_id" value={""} />
        <input type="hidden" name="admin_email" value={"Yes"} />
        
        <input type="hidden" name="bot-field" />

        
        
        <Row className="custom-text-field-wrapper no_direction">
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}                  
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError ? true : '' }
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>
                
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>
                
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
                
                </>
              );
            }
          })
        }
        </Row>
      </Form>

      
    </div>
  );
}


const ContactFormPage = (props) => (
  <ContactForm {...props} />
  
)

export default ContactFormPage