import React from "react"
import { Container} from "react-bootstrap";

// Components
import SectionWrapper from "../SectionWrapper";
import OffMarketForm from "./OffMarketForm";

import Contact_Form from "../forms/contact-form";
import Career_Form from "../forms/career-form";
import OffMarket_Form from "../forms/offmarket-properties";
import Sell_To_Us_Form from "../forms/sell-to-us-form";
import Member_Form from "../forms/member-form";


// Styles
import './forms_styles.scss';

const FormSection = ( { Module, to_email_id } ) => {

    //console.log("Module ==>", Module)
    return (
        <SectionWrapper className={`off-market-properties-wrapper ${Module.Form_Type === "Career_Form" ? "white_bg" : ""}`}>
            
            <Container>
                
                <div className="off-market-properties-header">
                    {Module.Title && 
                    <h2 className="general-heading">
                        {Module.Title}
                    </h2>
                    }
                    
                    {Module.Content && 
                    <p className={ `general-subheading` }>
                        { Module.Content }
                    </p>
                    }   
                </div>
                
                <div className="off-market-properties-body">
                    <div className="form-wrapper">

                        {Module.Form_Type === "Off_Market_Form" && <OffMarket_Form />}
                        {Module.Form_Type === "Sell_To_Us_Form" && <Sell_To_Us_Form />}
                        {Module.Form_Type === "Contact_Form" && <Contact_Form />}
                        {Module.Form_Type === "Individual_Contact" && <Member_Form to_email_id={to_email_id} />}
                        {Module.Form_Type === "Career_Form" && <Career_Form />}  
                        {Module.Form_Type === "Career_Details" && <Career_Form job_title={Module.job_title ? Module.job_title : ""} />}                        
                    </div>
                    
                </div>
                
            </Container>
        </SectionWrapper>
    )
}

export default FormSection
