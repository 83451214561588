import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const FileField = ({fieldClass, labelClass, type, name, value, required, placeholder, handlechange, accept, label, grpmd, field_id, show_formats, multiple, errorMsg, accept_file_formats}) => (
  <Form.Group as={Col} md={grpmd} controlId={"validation" + name}>
    { label
      ? <Form.Label className={labelClass}>{label}{required ? ' *' : ''}</Form.Label>
      : ''
    }
    <div className={fieldClass}>
      <Form.Control
        required={required}
        type={type}
        name={name}
        value={value}
        onChange={handlechange}
        accept={accept}
        id={field_id}
        multiple={multiple}
      />
      <label for={field_id} className="custom-file-label">{placeholder}</label>

      {show_formats && <div className="file_formats">{accept_file_formats}</div>}

      {/* {errorMsg && <div className={`error_msg ${name}`}>{errorMsg}</div>} */}
    </div>
  </Form.Group>
);

export default FileField;